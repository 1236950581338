import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { format } from 'date-fns';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import {
    CrowdsourcingLabelDataQuery,
    LocaleString,
    Maybe,
    ProductColors,
} from '../../graphql-types';
import { useProductPrice } from '../api/centra';
import { usePreorderStockForProductId } from '../api/np-backend/preorderStock';
import constants from '../constants';
import { I18n, I18nStr, useDateFnsLocale, useLanguage, useLocalizedString } from '../i18n';
import * as sanity from '../sanity-image';
import { OpenedLock } from '../svg/OpenedLock';
import { CrowdfundingImageLabelStatus, getDaysLeft } from './CrowdfundingStatus';
import { fontSize, fontStyle, imgStyling, space, weight } from './styles';
import { findFirstImage, Image } from '../lib/firstImage';
import { getImagesForGender } from '../lib/imageByGender';
import { GenderId } from '../gender';

export type ProductCardData = {
    product: ProductCardProduct;
    title?: Maybe<LocaleString>;
    image?: Maybe<SanityImageSource>;
};

export type ProductCardProduct = {
    centraId: string;
    id?: string;
    productState?: Maybe<string>;
    colors?: Maybe<Array<Maybe<ProductColors> | undefined>>;
    crowdfundingGoal?: Maybe<number>;
    crowdfundingDate?: Maybe<string>;
    title: LocaleString;
    slug: LocaleString;
    sanityProduct: {
        productCardTitle?: Maybe<LocaleString>;
        productCardImageTag?: Maybe<LocaleString>;
        images?: Maybe<Image[]>;
    };
};

export const ProductCard: FC<{
    productCard: ProductCardData;
    imgSizes: string;
    gender?: GenderId;
    className?: string;
    colspan?: number;
}> = ({ productCard, imgSizes, className, colspan = 1, gender = 'not_applicable' }) => {
    const product = productCard.product;
    const datefnsLocale = useDateFnsLocale();
    const lang = useLanguage();
    const preorderStock = usePreorderStockForProductId(product.centraId || product.id || 'noId');
    const bottomLabel =
        product.productState === 'preorder' ? (
            preorderStock.data?.deliveryDate && (
                <>
                    {format(new Date(preorderStock.data?.deliveryDate), 'MMMM', {
                        locale: datefnsLocale,
                    })}
                </>
            )
        ) : product.productState === 'crowdFunding' ? (
            <CrowdfundingImageLabelStatus {...{ product }} />
        ) : null;

    const isCrowdsourcing = product.productState === 'crowdSourcing';
    const topLabel = isCrowdsourcing && <CrowdsourcingLabel />;

    const daysLeft =
        product.productState === 'crowdFunding' &&
        product.crowdfundingDate &&
        getDaysLeft(new Date(), new Date(product.crowdfundingDate));

    const price = useProductPrice(product.centraId).data;
    const mainTitle = useLocalizedString(product.title);
    const overrideTitle = useLocalizedString(product.sanityProduct.productCardTitle);
    const title = productCard.title || overrideTitle || mainTitle;

    const images = product.sanityProduct.images;
    const genderImages = images && getImagesForGender(images, gender);
    const image = productCard.image || findFirstImage(genderImages || images);
    const sanityImageTag = !bottomLabel && product.sanityProduct.productCardImageTag;
    const fitQuery = gender === 'men' ? '?fit=men' : gender === 'women' ? '?fit=women' : '';

    return (
        <Link
            className={className}
            to={`/${lang}/shop/${product.slug?.[lang]}${fitQuery}`}
            css={`
                color: inherit;
                font-weight: inherit;
                ${fontSize.regular}
                display: block;
                img {
                    transition: filter 100ms ease-out;
                }
                &:hover img {
                    filter: brightness(80%);
                }
            `}
        >
            <div
                css={`
                    width: 100%;
                    position: relative;
                `}
            >
                {image && (
                    <img
                        loading="lazy"
                        css={`
                            ${imgStyling};
                            display: block;
                            width: 100%;
                            max-width: 100%;
                            margin-bottom: ${space(1)};
                        `}
                        src={sanity.urlFor(image).width(730).height(937).url() || ''}
                        srcSet={sanity.srcSetRatio(
                            [320, 375, 640, 751, 1000, 1600, 2000],
                            // 120 * (colspan - 1) is a hack until we know
                            // how to calculate the correct ratio. We need this
                            // factor because we span the gap
                            937 / (730 * colspan + 120 * (colspan - 1)),
                            image
                        )}
                        sizes={imgSizes}
                    />
                )}
                {bottomLabel && <ImageLabel topOrBottom={'bottom'}>{bottomLabel}</ImageLabel>}
                {topLabel && <ImageLabel topOrBottom={'top'}>{topLabel}</ImageLabel>}
                {sanityImageTag && (
                    <div
                        css={`
                            position: absolute;
                            padding: ${space(2)} ${space(3)};
                            bottom: 0;
                            left: 0;
                            top: 0;
                            right: 0;
                            color: white;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            background: linear-gradient(
                                    180deg,
                                    rgba(0, 0, 0, 0) 81.68%,
                                    rgba(0, 0, 0, 0.7) 98.32%
                                ),
                                url(5176d0bcbea419ec6d8c9ef6a6af04e2f8d6f5e0-3840x4800.jpg);
                        `}
                    >
                        <I18nStr>{sanityImageTag}</I18nStr>
                    </div>
                )}
            </div>
            <div>
                <I18nStr>{title}</I18nStr>
            </div>
            {isCrowdsourcing ? (
                <div
                    css={`
                        color: ${constants.colors.pallet.crowdsourcing};
                        ${weight.medium};
                    `}
                >
                    <I18n>joinTheDiscussion</I18n>
                </div>
            ) : daysLeft ? (
                <div>
                    {daysLeft} <I18n>daysLeft</I18n>
                </div>
            ) : (
                <div
                    css={`
                        display: flex;
                        margin: ${space(1)} 0;
                    `}
                >
                    {(product.colors || []).map(color => (
                        <div
                            key={color?.hex}
                            css={`
                                background: ${color?.hex};
                                width: 16px;
                                height: 16px;
                                border-radius: 100%;
                                margin-right: 4px;
                            `}
                        />
                    ))}
                </div>
            )}
            {!isCrowdsourcing && price && (
                <div
                    css={`
                        color: #737373;

                        /* For some reason the browser (firefox) adds a scrollbar without this. And it
                        only happens when using the acumin pro condensed font */
                        min-height: 26px;
                    `}
                >
                    {price}
                </div>
            )}
        </Link>
    );
};

const ImageLabel: FC<{ topOrBottom: 'top' | 'bottom'; children: React.ReactNode }> = ({
    children,
    topOrBottom,
}) => {
    return (
        <div
            css={`
                position: absolute;
                ${topOrBottom}: ${space(2)};
                left: ${space(2)};
                padding: 0 ${space(1)};
                ${fontStyle.label};
                background: white;
                border-radius: 3px;
                box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);
                color: #737373;
            `}
        >
            {children}
        </div>
    );
};

export const CrowdsourcingLabel: FC = () => {
    const crowdsourcingTextData = useStaticQuery<CrowdsourcingLabelDataQuery>(graphql`
        query CrowdsourcingLabelData {
            crowdsourcingTexts: sanityProductStateTexts(productState: { eq: "crowdSourcing" }) {
                productPageTag {
                    nb
                    en
                }
            }
        }
    `);

    return (
        <div
            css={`
                display: flex;
                align-items: center;
                color: ${constants.colors.pallet.crowdsourcing};
                svg {
                    margin-right: ${space(1)};
                }
            `}
        >
            <OpenedLock />
            <I18nStr>{crowdsourcingTextData.crowdsourcingTexts?.productPageTag}</I18nStr>
        </div>
    );
};

export const FakeProductCard: FC<{
    product: {
        title?: LocaleString | null;
        subtitle?: LocaleString | null;
        image?: SanityImageSource | null;
        link?: LocaleString | null;
    };
    imgSizes: string;
    className?: string;
    colspan?: number;
}> = ({ product, imgSizes, className, colspan = 1 }) => {
    const lang = useLanguage();

    const title = useLocalizedString(product.title);
    const subTitle = useLocalizedString(product.subtitle);

    const image = product.image!;

    return (
        <Link
            className={className}
            to={`/${lang}/${product.link?.[lang]}`}
            css={`
                color: inherit;
                font-weight: inherit;
                ${fontSize.regular}
                display: block;
                img {
                    transition: filter 100ms ease-out;
                }
                &:hover img {
                    filter: brightness(80%);
                }
            `}
        >
            <div
                css={`
                    width: 100%;
                    position: relative;
                `}
            >
                <img
                    loading="lazy"
                    css={`
                        ${imgStyling};
                        display: block;
                        width: 100%;
                        max-width: 100%;
                        margin-bottom: ${space(1)};
                    `}
                    src={sanity.urlFor(image).width(730).height(937).url() || ''}
                    srcSet={sanity.srcSetRatio(
                        [320, 375, 640, 751, 1000, 1600, 2000],
                        // 120 * (colspan - 1) is a hack until we know
                        // how to calculate the correct ratio. We need this
                        // factor because we span the gap
                        937 / (730 * colspan + 120 * (colspan - 1)),
                        image
                    )}
                    sizes={imgSizes}
                />
            </div>
            <div>
                <I18nStr>{title}</I18nStr>
            </div>
            {subTitle && (
                <div
                    css={`
                        color: #737373;

                        /* For some reason the browser (firefox) adds a scrollbar without this. And it
                        only happens when using the acumin pro condensed font */
                        min-height: 26px;
                    `}
                >
                    {subTitle}
                </div>
            )}
        </Link>
    );
};
