import React, { FC, useState } from 'react';
import { VisuallyHidden } from '@reach/visually-hidden';
import { Hamburger } from '../../svg/Hamburger';
import Portal from '../Portal';
import { buttonReset, linkReset, space, zIndex } from '../styles';
import { I18n, I18nStr, useLanguage } from '../../i18n';
import { CloseHamburger } from '../../svg/CloseHamburger';
import MenuCart from '../shared/navigation/menuCart';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { HamburgerDialogQuery, NewHamburgerNavigationQuery } from '../../../graphql-types';
import { useLockBodyScroll } from '../../lib/hooks';
import { Language } from '../../interfaces';
import { useLoginState } from '../auth/LoginContext';
import { RouteLink } from '../shared/navigation/NewNavigation';
import { ArrowNext } from '../../svg/ArrowNext';

export const NewHamburgerMenu: FC<{ className?: string }> = ({ className }) => {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <>
            <button
                className={className}
                onClick={() => setShowMenu(true)}
                css={`
                    ${buttonReset}
                    transition: transform 200ms ease-in-out;
                    border-radius: 3px;
                    // padding: 5px 10px 5px 14px;
                    color: #000;

                    &:hover {
                        transition: transform 200ms ease-in-out;
                        color: #737373;
                    }
                `}
            >
                <Hamburger />
                <VisuallyHidden>
                    <I18n>menu</I18n>
                </VisuallyHidden>
            </button>
            {showMenu && <HamburgerDialog close={() => setShowMenu(false)} />}
        </>
    );
};

const HamburgerDialog: FC<{ close: () => void }> = ({ close }) => {
    const { isLoggedIn } = useLoginState();
    useLockBodyScroll();

    const [showCollapsible, setShowCollapsible] = useState<Record<number, boolean>>({});
    const toggleCollapsable = (id: number) => () => {
        setShowCollapsible(prevState => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const lang = useLanguage();
    const data = useStaticQuery<NewHamburgerNavigationQuery>(graphql`
        query NewHamburgerNavigation {
            sanityMenu(_id: { eq: "main-menu" }) {
                menuItem {
                    _key
                    linkNb {
                        __typename
                        ... on SanityContentPage {
                            id
                            slug {
                                en {
                                    current
                                }
                                nb {
                                    current
                                }
                            }
                        }
                        ... on SanityLandingPage {
                            id
                            path
                        }
                    }
                    linkEn {
                        __typename
                        ... on SanityContentPage {
                            id
                            slug {
                                en {
                                    current
                                }
                                nb {
                                    current
                                }
                            }
                        }
                        ... on SanityLandingPage {
                            id
                            path
                        }
                    }
                    title {
                        en
                        nb
                    }
                    showTo
                }
            }
            allSanityNavbarItem {
                nodes {
                    groups {
                        title {
                            nb
                            en
                        }
                        routes {
                            title {
                                en
                                nb
                            }
                            linkEn
                            linkNb
                            showTo
                        }
                        groupRoute {
                            linkEn
                            linkNb
                            title {
                                en
                                nb
                            }
                        }
                    }
                    title {
                        en
                        nb
                    }
                    priority
                }
            }
        }
    `);

    const menuItems = data.allSanityNavbarItem?.nodes;

    const links = data.sanityMenu?.menuItem || [];

    return (
        <Portal>
            <div
                css={`
                    position: fixed;
                    left: 0;
                    right: 0;
                    top: -1px;
                    bottom: 0;
                    background: #f2f2f2;
                    z-index: ${zIndex.modal};
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 300;
                `}
            >
                <div
                    css={`
                        position: absolute;
                        right: 24px;
                        left: 24px;
                        top: 0px;

                        height: 100px;
                        width: calc(100%-48px);
                        max-height: 100px;

                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                    `}
                >
                    <button
                        onClick={close}
                        css={`
                            ${buttonReset};
                            margin: 0;
                            display: inline-flex;
                            height: ${space(2)};
                            margin-right: ${space(2)};
                        `}
                    >
                        <CloseHamburger />
                        <VisuallyHidden>
                            <I18n>close</I18n>
                        </VisuallyHidden>
                    </button>
                    <MenuCart onClick={close} />
                </div>
                <div
                    css={`
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        width: 100%;
                        height: 100%;
                    `}
                >
                    <div
                        css={`
                            display: flex;
                            flex-direction: column;

                            border-bottom: 1px solid #d6d6d6;
                            overflow-y: auto;
                            margin-top: 100px;
                        `}
                    >
                        {menuItems.map((item, i: number) => (
                            <li
                                key={i}
                                css={`
                                    list-style-type: none;
                                    color: black;
                                    border-top: 1px solid #d6d6d6;
                                    order: ${item.priority || 0};
                                `}
                                id={`item-${i}`}
                            >
                                <div
                                    onClick={toggleCollapsable(i)}
                                    // onClick={() => {
                                    //     // if (activeItem === i) {
                                    //     //     return setActiveItem(null);
                                    //     // }
                                    //     // https://stackoverflow.com/questions/49820013/javascript-scrollintoview-smooth-scroll-and-offset

                                    //     // const element = document.getElementById(`portal`);
                                    //     toggleCollapsable(i);
                                    //     // setActiveItem(i);
                                    //     // element?.scrollTo(500, 1000);
                                    //     // document.body.scrollTo(0, 1000);
                                    // }}
                                    css={`
                                        display: flex;
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 32px;
                                        line-height: 25px;
                                        cursor: pointer;
                                        padding: 28px;
                                        justify-content: space-between;
                                    `}
                                >
                                    {item?.title?.[lang]}
                                    <span
                                        css={`
                                            width: 20px;
                                            display: inline-flex;
                                            justify-content: center;
                                            align-items: center;

                                            ${showCollapsible[i] === true && 'rotate: 90deg;'}
                                        `}
                                    >
                                        <ArrowNext />
                                    </span>
                                </div>
                                {/* {activeItem === i && ( */}
                                {showCollapsible[i] === true && (
                                    <div>
                                        {item?.groups?.map(group => (
                                            <ul
                                                key={group?.title?.[lang]}
                                                css={`
                                                    // reset ul style
                                                    list-style-type: none;
                                                    margin-block-start: 0px;
                                                    margin-block-end: 0px;
                                                    margin-inline-start: 0px;
                                                    margin-inline-end: 0px;
                                                    padding-inline-start: 0px;

                                                    text-align: start;
                                                    padding-left: 28px;
                                                    margin-bottom: 28px;
                                                `}
                                            >
                                                <li
                                                    css={`
                                                        font-weight: 600;
                                                        margin-bottom: 10px;
                                                        padding-left: 28px;
                                                    `}
                                                >
                                                    {group?.groupRoute?.[
                                                        lang === 'en' ? 'linkEn' : 'linkNb'
                                                    ] ? (
                                                        <RouteLink
                                                            to={
                                                                group.groupRoute[
                                                                    lang === 'en'
                                                                        ? 'linkEn'
                                                                        : 'linkNb'
                                                                ] || ''
                                                            }
                                                            disabled={false}
                                                        >
                                                            {group?.groupRoute?.title?.[lang] ||
                                                                group?.title?.[lang] ||
                                                                ''}
                                                        </RouteLink>
                                                    ) : (
                                                        group?.title?.[lang] || ''
                                                    )}
                                                </li>
                                                {group?.routes
                                                    ?.filter(route => {
                                                        if (
                                                            isLoggedIn &&
                                                            route?.showTo == 'logged_out'
                                                        ) {
                                                            return false;
                                                        }
                                                        if (
                                                            !isLoggedIn &&
                                                            route?.showTo == 'logged_in'
                                                        ) {
                                                            return false;
                                                        }
                                                        return true;
                                                    })
                                                    .map((route, i) => (
                                                        <li
                                                            key={i}
                                                            css={`
                                                                font-style: normal;
                                                                font-weight: 500;
                                                                font-size: 19px;
                                                                line-height: 180%;
                                                                cursor: pointer;
                                                                padding-left: 28px;
                                                            `}
                                                        >
                                                            {route?.[
                                                                lang === 'en' ? 'linkEn' : 'linkNb'
                                                            ] && (
                                                                <RouteLink
                                                                    to={
                                                                        route[
                                                                            lang === 'en'
                                                                                ? 'linkEn'
                                                                                : 'linkNb'
                                                                        ] || ''
                                                                    }
                                                                    disabled={false}
                                                                >
                                                                    {route?.title?.[lang]}
                                                                </RouteLink>
                                                            )}
                                                        </li>
                                                    ))}
                                            </ul>
                                        ))}
                                    </div>
                                )}
                            </li>
                        ))}
                    </div>
                    <div
                        css={`
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;

                            padding: 24px;
                        `}
                    >
                        {links
                            .filter(item => {
                                if (isLoggedIn && item?.showTo == 'logged_out') {
                                    return false;
                                }
                                if (!isLoggedIn && item?.showTo == 'logged_in') {
                                    return false;
                                }
                                return true;
                            })
                            .map(item => (
                                <Link
                                    to={getLink(item, lang)}
                                    key={item?._key}
                                    onClick={close}
                                    css={`
                                        ${linkReset};
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 25px;
                                        color: #737373;
                                    `}
                                >
                                    <I18nStr>{item?.title}</I18nStr>
                                </Link>
                            ))}
                    </div>
                </div>
            </div>
        </Portal>
    );
};

function getLink(item: MenuItem, lang: Language): string {
    if (!item) {
        return '';
    }

    let linkItem = lang === 'en' && item.linkEn ? item.linkEn : item.linkNb;

    if (!linkItem) {
        return '';
    }

    if (linkItem.__typename === 'SanityContentPage') {
        return `/${lang}/content/${linkItem.slug?.[lang]?.current}`;
    }
    if (linkItem.__typename === 'SanityLandingPage') {
        return `/${lang}/${linkItem.path}`;
    }
    return '';
}

type MenuItem = NonNullable<NonNullable<HamburgerDialogQuery['sanityMenu']>['menuItem']>[0];
