exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-testerror-tsx": () => import("./../../../src/pages/testerror.tsx" /* webpackChunkName: "component---src-pages-testerror-tsx" */),
  "component---src-templates-account-tsx": () => import("./../../../src/templates/account.tsx" /* webpackChunkName: "component---src-templates-account-tsx" */),
  "component---src-templates-check-payment-page-tsx": () => import("./../../../src/templates/checkPaymentPage.tsx" /* webpackChunkName: "component---src-templates-check-payment-page-tsx" */),
  "component---src-templates-checkout-page-checkout-page-tsx": () => import("./../../../src/templates/checkoutPage/checkoutPage.tsx" /* webpackChunkName: "component---src-templates-checkout-page-checkout-page-tsx" */),
  "component---src-templates-community-redirect-tsx": () => import("./../../../src/templates/communityRedirect.tsx" /* webpackChunkName: "component---src-templates-community-redirect-tsx" */),
  "component---src-templates-confirmation-page-crowdfunding-confirmation-page-tsx": () => import("./../../../src/templates/confirmationPage/crowdfundingConfirmationPage.tsx" /* webpackChunkName: "component---src-templates-confirmation-page-crowdfunding-confirmation-page-tsx" */),
  "component---src-templates-confirmation-page-payment-success-page-tsx": () => import("./../../../src/templates/confirmationPage/paymentSuccessPage.tsx" /* webpackChunkName: "component---src-templates-confirmation-page-payment-success-page-tsx" */),
  "component---src-templates-content-page-tsx": () => import("./../../../src/templates/contentPage.tsx" /* webpackChunkName: "component---src-templates-content-page-tsx" */),
  "component---src-templates-front-page-front-page-tsx": () => import("./../../../src/templates/frontPage/frontPage.tsx" /* webpackChunkName: "component---src-templates-front-page-front-page-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/landingPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-product-page-product-page-tsx": () => import("./../../../src/templates/productPage/productPage.tsx" /* webpackChunkName: "component---src-templates-product-page-product-page-tsx" */),
  "component---src-templates-search-tsx": () => import("./../../../src/templates/search.tsx" /* webpackChunkName: "component---src-templates-search-tsx" */),
  "component---src-templates-shop-page-shop-page-tsx": () => import("./../../../src/templates/shopPage/shopPage.tsx" /* webpackChunkName: "component---src-templates-shop-page-shop-page-tsx" */)
}

