/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import * as sanity from '../sanity-image';

const SEO: React.FC<{
    description?: string | null;
    lang: string;
    meta?: HTMLMetaElement[];
    title?: string | null;
    ogImage?: string | null;
    twitterImage?: string | null;
    canonicalURL?: string;
    noindex?: boolean;
}> = ({
    description = '',
    lang,
    meta,
    title = 'Northern Playground',
    ogImage = '',
    twitterImage = '',
    canonicalURL,
    noindex = false,
}) => {
    const { site, allSanityFrontPage } = useStaticQuery(
        graphql`
            query Seo {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
                allSanityFrontPage {
                    nodes {
                        header {
                            textShort {
                                en
                                nb
                            }
                            image {
                                asset {
                                    url
                                }
                                crop {
                                    _key
                                    _type
                                    bottom
                                    left
                                    right
                                    top
                                }
                            }
                            imageMobile {
                                asset {
                                    url
                                }
                                crop {
                                    _key
                                    _type
                                    bottom
                                    left
                                    right
                                    top
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    const header = allSanityFrontPage.nodes[0].header;
    const image = header.image;
    const imageMobile = header.imageMobile;
    const headerDescription: string = header.textShort[lang];

    const metaDescription =
        description?.replaceAll('\\n', ' ') ||
        headerDescription.replaceAll('\\n', ' ') ||
        site.siteMetadata.description;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title?.trim() ?? 'Northern Playground'}
            titleTemplate={`%s – ${site.siteMetadata.title}`}
            meta={[
                {
                    name: 'title',
                    content: title?.trim() ?? 'Northern Playground',
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: `viewport`,
                    content: `width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no`,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: 'og:image',
                    content:
                        ogImage === ''
                            ? sanity.urlFor(image).width(1200).height(630).url()
                            : ogImage,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: 'twitter:image',
                    content:
                        twitterImage === ''
                            ? sanity.urlFor(imageMobile).width(450).height(450).url()
                            : twitterImage,
                },
            ].concat(meta ?? [])}
        >
            {canonicalURL && <link rel="canonical" href={canonicalURL} />}
            {noindex && <meta name="robots" content="noindex"></meta>}
        </Helmet>
    );
};

export default SEO;
