import axios, { AxiosError } from 'axios';
import { useEffect, useReducer, Reducer, useCallback, useMemo } from 'react';
import { useNPContext } from '../npContext';
import { RemoteData } from './remoteData';

const checkoutApi = process.env.GATSBY_CENTRA_API;

// https://www.robinwieruch.de/react-hooks-fetch-data/

export const useCentraApi = <T,>({
    url,
    method,
    data,
}: {
    url: string | void;
    method: 'GET' | 'POST';
    data?: unknown;
}): [RemoteData<T>, () => void] => {
    let context = useNPContext();
    const apiToken = context.centraApiToken;
    return useCentraApiWithToken({ url, method, data, apiToken });
};

export const createCentraAxiosApi = (apiToken: string) => {
    // Cache the axios instance and make sure it updates if the apiToken changes

    let headers: { [key: string]: string } = {};
    if (apiToken) {
        headers['api-token'] = apiToken;
    }

    return axios.create({
        baseURL: checkoutApi,
        headers,
    });
};

// T is the type of a normal (successful) response
// E is the type of an error response.  It can for example be string, null, or T.
export const useCentraApiWithToken = <T,>({
    url,
    method,
    data,
    apiToken,
}: {
    url: string | void;
    method: 'GET' | 'POST';
    data: unknown;
    apiToken: string;
}): [RemoteData<T>, () => void] => {
    const dataJson = JSON.stringify(data);
    const reducer: Reducer<RemoteData<T>, Action<T>> = dataFetchReducer;

    const [state, dispatch] = useReducer(reducer, {
        isLoading: true,
        error: undefined,
        data: undefined,
    });

    useEffect(() => {
        dispatch({ type: 'FETCH_INIT' });
    }, []);

    const fetchData = useCallback(() => {
        let didCancel = false;
        let headers: { [key: string]: string } = { 'Content-Type': 'application/json' };
        if (apiToken) {
            headers['api-token'] = apiToken;
        }

        const fetchDataInner = async () => {
            try {
                // In case the url is undefined, we don't do any fetch.
                // As the fetch is automatically initiated, this makes it possible to delay
                // the fetch until the url/options object is known.
                const result = url
                    ? await axios({ baseURL: checkoutApi, url, method, data: dataJson, headers })
                    : { data: null };

                if (!didCancel) {
                    dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
                }
            } catch (error: any) {
                if (!didCancel) {
                    console.error(error);
                    dispatch({ type: 'FETCH_FAILURE', error });
                }
            }
        };

        fetchDataInner().catch(err => {
            console.error(err);
        });

        return () => {
            didCancel = true;
        };
    }, [url, dispatch, apiToken, dataJson, method]);

    useEffect(() => {
        dispatch({ type: 'FETCH_INIT' });
        return fetchData();
    }, [fetchData]);

    return [state, fetchData];
};

export type Action<T> =
    | {
          type: 'FETCH_INIT';
      }
    | {
          type: 'FETCH_SUCCESS';
          payload: T;
      }
    | {
          type: 'FETCH_FAILURE';
          error: AxiosError<unknown>;
      };

const dataFetchReducer = <T,>(state: RemoteData<T>, action: Action<T>): RemoteData<T> => {
    switch (action.type) {
        case 'FETCH_INIT':
            return {
                isLoading: true,
                error: undefined,
                data: undefined,
            };
        case 'FETCH_SUCCESS':
            return {
                isLoading: false,
                error: undefined,
                data: action.payload,
            };
        case 'FETCH_FAILURE':
            return {
                isLoading: false,
                error: action.error,
                data: undefined,
            };
        default:
            throw new Error();
    }
};
