import React, { useEffect, useState, StrictMode } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/browser';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { FlagProvider } from '@unleash/proxy-client-react';

import { ErrorPage } from './components/error-page';
import { LoginContextProvider } from './components/auth/LoginContext';
import { NpContextProvider } from './npContext';

// fwi the wrapped state exists because of the ability for multiple tabs to effect the state.
// It basically tracks if the current tab caused the state change or another window -
// so localUpdate refers to the “tab” not the “client” if that makes sense
//
// The function readValueFromLocalStorage uses the wrapping functions
// (from Benjamin)

function getUrlParams(params: any[]) {
    const url = window.location;
    const urlObject = new URL(url.href);
    const result: any = {};
    params.forEach((param: any) => {
        const id = urlObject.searchParams.get(param);
        result[param] = id;
    });
    return result;
}

// We want to capture the mc_cid (mailchimp id) before we start changing the URL
const MC_CID = typeof window !== 'undefined' && getUrlParams(['mc_cid']);

const checkoutApi = process.env.GATSBY_CENTRA_API;

/**
 * @deprecated Dont use this, instead use one of the hooks in `api/centra`
 */
async function liveVariants(
    variantIds: any,
    variantFunction: (data: any) => void,
    wrappedState: any
) {
    const url = `${checkoutApi}/products`;

    const headers: any = { 'Content-Type': 'application/json' };
    const data = { ...wrappedState.state };
    if (!data) {
        console.log('no local data when fetching centra price. This is a bug');
    }
    const centra = data.centra;
    if (centra) {
        headers['api-token'] = centra.token;
    }

    const options = {
        headers: headers,
        method: 'POST',
        body: JSON.stringify({
            products: variantIds,
        }),
    };

    fetch(url, options)
        .then(response => response.json())
        .then(data => (data as any).products)
        .then(data => {
            variantFunction(data);
        })
        .catch(err => {
            console.log(err);
        });
}

// simple interface to local storage -------------------------------------------

const defaultStateValue = {
    userPreferences: {
        language: null,
        country: null,
        hasSeenSignup: false,
        hasSeenFrontPage: false,
        seenProducts: 0,
    },
    centra: null,
    mc: {
        mailchimpCampaignId: null,
        mailchimpLandingSite: null,
    },
};

const localStorageKey = 'northernPlayground';

function wrapLocalUpdate(state: any) {
    return { localUpdate: true, state: state };
}

function wrapRemoteUpdate(state: any) {
    return { localUpdate: false, state: state };
}

function readValueFromLocalStorage() {
    try {
        let v = JSON.parse(window.localStorage.getItem(localStorageKey) as any);
        return v ? wrapRemoteUpdate(v) : wrapLocalUpdate(defaultStateValue);
    } catch (err: any) {
        if (typeof window !== `undefined`) {
            // Dont print the error if it's "window is undefined"
            console.error('error reading from local storage', err);
        }
        return wrapLocalUpdate(defaultStateValue);
    }
}

function maybeWriteToLocalStorage(wrappedState: any) {
    if (wrappedState.localUpdate) {
        window.localStorage.setItem(localStorageKey, JSON.stringify(wrappedState.state));
    }
}

function parseEvent(e: any) {
    try {
        if (e.key === localStorageKey) {
            return JSON.parse(e.newValue);
        } else {
            console.log('new value is null');
            return null;
        }
    } catch (err: any) {
        console.log('error', err, 'when parsing', e.newValue);
        return null;
    }
}

// TODO: Remove all usage of this function, convert to `safeCentraCheckoutApiFetch`
async function safeCentraCheckoutApiFetchDeprecated(
    checkoutURI: any,
    options: any,
    onSuccessFn: any,
    onErrorFunction = (responseCode: any, maybeCentraErrors: any) => {}
) {
    const url = `${checkoutApi}/${checkoutURI}`;
    try {
        const response = await fetch(url, options);
        const contentType = response.headers.get('content-type');
        const hasJson = contentType && contentType.indexOf('application/json') !== -1;
        let maybeJson = null;
        if (hasJson) {
            maybeJson = await response.json();
        }
        if ((maybeJson && (maybeJson as any).errors) || !response.ok) {
            console.log('safeCentraCheckoutApiFetch error', url, response.status, maybeJson);
            onErrorFunction(response.status, maybeJson);
        } else {
            onSuccessFn(maybeJson);
        }
    } catch (err: any) {
        onErrorFunction('599', { errors: { message: err.message } });
    }
}

async function safeCentraCheckoutApiFetch<T>(
    checkoutURI: string,
    options?: AxiosRequestConfig
): Promise<T> {
    const url = `${checkoutApi}/${checkoutURI}`;
    const response = await axios(url, options);

    return response.data;
}

function addCentraVoucher(
    voucherCode: any,
    onErrorFn: any,
    wrappedState: any,
    setWrappedState: any
) {
    let headers: any = { 'Content-Type': 'application/json' };
    let data = { ...wrappedState.state };
    if (!data) {
        // maybe not a bug...
        // people want to add vouchers to an empty basket?
        console.log('no local data when adding voucher. This is a bug');
    }
    let centra = data.centra;
    if (centra) {
        headers['api-token'] = centra.token;
    }
    const body = JSON.stringify({ voucher: voucherCode });
    const options = { headers: headers, method: 'POST', body: body };
    const url = `vouchers`;
    safeCentraCheckoutApiFetchDeprecated(
        url,
        options,
        (result: any) => {
            data.centra = result;
            setWrappedState(wrapLocalUpdate(data));
        },
        onErrorFn
    );
}

// auto country detection

async function autoCountry(successFn: any, errorFn: any) {
    const url = `countries/auto`;
    const options = {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
    };
    safeCentraCheckoutApiFetchDeprecated(
        url,
        options,
        (result: any) => {
            successFn(result.country);
        },
        errorFn
    );
}

function deleteCentraVoucher(
    voucherCode: any,
    onErrorFn: any,
    wrappedState: any,
    setWrappedState: any
) {
    let headers: any = { 'Content-Type': 'application/json' };
    let data = { ...wrappedState.state };
    if (!data) {
        // maybe not a bug...
        // people want to add vouchers to an empty basket?
        console.log('no local data when adding voucher. This is a bug');
    }
    let centra = data.centra;
    if (centra) {
        headers['api-token'] = centra.token;
    }
    const body = JSON.stringify({ voucher: voucherCode });
    const options = { headers: headers, method: 'DELETE', body: body };
    const url = `vouchers`;
    safeCentraCheckoutApiFetchDeprecated(
        url,
        options,
        (result: any) => {
            data.centra = result;
            setWrappedState(wrapLocalUpdate(data));
        },
        onErrorFn
    );
}

function addCentraItem(centraItemId: any, wrappedState: any, setWrappedState: any) {
    let headers: any = { 'Content-Type': 'application/json' };
    let data = { ...wrappedState.state };
    if (!data) {
        console.log('no local data when adding centra item. This is a bug');
    }
    let centra = data.centra;
    if (centra) {
        headers['api-token'] = centra.token;
    }
    let options = { headers: headers, method: 'POST' };
    const url = `items/${centraItemId}`;
    safeCentraCheckoutApiFetchDeprecated(url, options, (result: any) => {
        data.centra = result;
        setWrappedState(wrapLocalUpdate(data));
    });
}

function clearStoredCentraData(wrappedState: any, setWrappedState: any) {
    const data = { ...wrappedState.state };
    data.centra = { token: data.centra.token };
    setWrappedState(wrapLocalUpdate(data));
}

async function refreshCentraSelection(wrappedState: any, setWrappedState: any) {
    let headers: { [key: string]: string } = { 'Content-Type': 'application/json' };
    let data = { ...wrappedState.state };
    if (!data) {
        console.log('no local data when adding centra item. This is a bug');
    }
    let centra = data.centra;
    if (centra) {
        headers['api-token'] = centra.token;
    }
    let options = { headers: headers };
    const url = `selection/`;

    let result = await safeCentraCheckoutApiFetch(url, options);

    const userAgent = typeof window !== 'undefined' ? window.navigator.userAgent : '';
    const isGoogleBot = userAgent.toLowerCase().indexOf('googlebot') > -1;
    const isStoreBot = userAgent.toLowerCase().indexOf('storebot-google') > -1;
    const isGoogleInspectionTool = userAgent.toLowerCase().indexOf('google-inspectiontool') > -1;
    if (isGoogleBot || isStoreBot || isGoogleInspectionTool) {
        // This is a ugly hack. Google does not like using geoip to determine the currency,
        // google mercant center crawls the site from whereever, and if the currency is not
        // the one we provided in the feed, it errors and we are not allowed to use the
        // product in ads.
        //
        // I think the correct thing to do here is to not use geoip, but instead have different
        // currencies on different domains (and crossreference using hreflang). However doing
        // that now would take a lot of work.
        //
        // Instead we assume that all ads are in NOK (which is what Northern Playground says
        // we can assume) and always shows NOK to googlebot.
        //
        // Context:
        // https://garasjen.slack.com/archives/CT1LHLTHQ/p1692095617498959 (from #centra-iterate on slck)
        // https://garasjen.slack.com/archives/GT0DVSA4D/p1692094528033039 (from #northern-playground-external on slack)
        await safeCentraCheckoutApiFetch('/countries/NO', {
            headers: {
                'Content-Type': 'application/json',
                'api-token': (result as any).token,
            },
            method: 'PUT',
        });
    }

    setWrappedState((oldState: any) => {
        let dataToUpdate = { ...oldState.state };
        dataToUpdate.centra = result;
        return wrapLocalUpdate(dataToUpdate);
    });
}

async function getNewSelection(wrappedState: any, setWrappedState: any) {
    let headers: { [key: string]: string } = { 'Content-Type': 'application/json' };
    let data = { ...wrappedState.state };
    if (!data) {
        console.log('no local data when adding centra item. This is a bug');
    }

    let options = { headers: headers };
    const url = `selection/`;

    let result = await safeCentraCheckoutApiFetch(url, options);

    setWrappedState((oldState: any) => {
        let dataToUpdate = { ...oldState.state };
        dataToUpdate.centra = result;
        return wrapLocalUpdate(dataToUpdate);
    });
}

function decreaseQuantityOfCentraItemLine(
    centraLineId: any,
    wrappedState: any,
    setWrappedState: any
) {
    adjustQuantityOfCentraItemLine(centraLineId, wrappedState, setWrappedState, 'DELETE');
}

function increaseQuantityOfCentraItemLine(
    centraLineId: any,
    wrappedState: any,
    setWrappedState: any
) {
    adjustQuantityOfCentraItemLine(centraLineId, wrappedState, setWrappedState, 'POST');
}

function adjustQuantityOfCentraItemLine(
    centraLineId: any,
    wrappedState: any,
    setWrappedState: any,
    method: any
) {
    let headers: any = { 'Content-Type': 'application/json' };
    let data = { ...wrappedState.state };
    if (!data) {
        console.log('no local data when adding centra item. This is a bug');
    }
    let centra = data.centra;
    if (centra) {
        headers['api-token'] = centra.token;
    }
    let url = `lines/${centraLineId}/quantity/1`;
    let options = { headers: headers, method: method };
    safeCentraCheckoutApiFetchDeprecated(url, options, (result: any) => {
        data.centra = result;
        setWrappedState(wrapLocalUpdate(data));
    });
}

function setCentraCountry(countryCode: any, wrappedState: any, setWrappedState: any) {
    const data = { ...wrappedState.state };
    if (data.userPreferences.country === countryCode) {
        console.log('no need to set country code again. This is a bug');
        return;
    }
    if (!countryCode) {
        console.log('dont know how to unset a country!');
        return;
    }
    const headers: any = { 'Content-Type': 'application/json' };
    const centra = data.centra;
    const url = `countries/${countryCode}`;
    if (centra) {
        headers['api-token'] = centra.token;
    }
    let options = { headers: headers, method: 'PUT' };
    safeCentraCheckoutApiFetchDeprecated(url, options, (result: any) => {
        data.centra = result;
        data.userPreferences.country = countryCode;
        setWrappedState(wrapLocalUpdate(data));
    });
}

function setCentraLanguage(languageCodeEg_en: any, wrappedState: any, setWrappedState: any) {
    const headers: any = { 'Content-Type': 'application/json' };
    const data = { ...wrappedState.state };
    const centra = data.centra;
    const url = `languages/${languageCodeEg_en}`;
    if (centra) {
        headers['api-token'] = centra.token;
    }
    let options = { headers: headers, method: 'PUT' };
    safeCentraCheckoutApiFetchDeprecated(url, options, (result: any) => {
        data.centra = result;
        data.userPreferences.language = languageCodeEg_en;
        setWrappedState(wrapLocalUpdate(data));
    });
}

async function signUpNewsLetterSubscription(
    wrappedState: any,
    onSuccessFn: any,
    onErrorFn: any,
    email: any,
    language: any
) {
    const headers: any = { 'Content-Type': 'application/json' };
    const data = { ...wrappedState.state };
    const centra = data.centra;
    const url = `newsletter-subscription/${email}`;

    if (centra) {
        headers['api-token'] = centra.token;
    }

    const options = {
        headers: headers,
        method: 'POST',
        body: JSON.stringify({
            // country: "string",
            language: language,
        }),
    };

    safeCentraCheckoutApiFetchDeprecated(url, options, onSuccessFn, onErrorFn);
}

async function initiateCentraPayment(
    language: any,
    customer: any,
    separateShippingAddress: any,
    wrappedState: any,
    setWrappedState: any,
    onSuccessFn: any,
    onErrorFn: any
) {
    const headers: any = { 'Content-Type': 'application/json' };
    const data = { ...wrappedState.state };
    const centra = data.centra;
    const mailchimp = data.mc;
    const url = 'payment';

    if (centra) {
        headers['api-token'] = centra.token;
    }

    const baseUrl =
        typeof window !== `undefined`
            ? document.location.hostname === 'localhost'
                ? 'http://localhost:8001'
                : document.location.origin
            : 'https://northernplayground.no';

    const paymentData: any = {
        paymentMethod: 'adyen-dropin',
        paymentReturnPage: `${baseUrl}/${language}/payment-redirect`,
        paymentFailedPage: `${baseUrl}/${language}/checkout/failed`,
        termsAndConditions: customer.termsAndConditions || false,
        address: {
            newsletter: customer.newsletter || false,
            email: customer.email,
            phoneNumber: customer.phone,
            firstName: customer.firstName,
            lastName: customer.lastName,
            address1: customer.address,
            address2: '',
            zipCode: customer.zipCode,
            city: customer.city,
            state: customer.state,
            country: customer.country,
        },
    };

    if (separateShippingAddress) {
        paymentData.shippingAddress = {
            email: customer.email,
            phoneNumber: customer.phone,
            firstName: customer.shippingFirstName,
            lastName: customer.shippingLastName,
            address1: customer.shippingAddress,
            address2: '',
            zipCode: customer.shippingZipCode,
            city: customer.shippingCity,
            state: customer.shippingState,
            country: customer.shippingCountry,
        };
    }

    if (mailchimp && mailchimp.mailchimpCampaignId && mailchimp.mailchimpLandingSite) {
        paymentData.additionalFields = {
            mailchimp_campaign_id: mailchimp.mailchimpCampaignId,
            mailchimp_landing_site: mailchimp.mailchimpLandingSite,
        };
    }

    const options = {
        headers: headers,
        method: 'POST',
        body: JSON.stringify(paymentData),
    };

    safeCentraCheckoutApiFetchDeprecated(
        url,
        options,
        (result: any) => {
            data.centraPaymentResult = null;
            data.mc = {};
            setWrappedState(wrapLocalUpdate(data));
            onSuccessFn(result);
        },
        onErrorFn
    );
}

async function centraPaymentResult(
    paymentMethodFields: any,
    wrappedState: any,
    setWrappedState: any,
    onSuccessFn: any,
    onErrorFn: any
) {
    const headers: any = { 'Content-Type': 'application/json' };
    const data = { ...wrappedState.state };
    const { centra } = data;
    const url = 'payment-result';

    if (centra) {
        headers['api-token'] = centra.token;
    }

    const options = {
        headers: headers,
        method: 'POST',
        body: JSON.stringify({
            paymentMethodFields: paymentMethodFields,
        }),
    };

    safeCentraCheckoutApiFetchDeprecated(
        url,
        options,
        (result: any) => {
            data.centra = null;
            setWrappedState(wrapLocalUpdate(data));
            onSuccessFn(result);
        },
        onErrorFn
    );
}

function setHasSeenSignup(wrappedState: any, setWrappedState: any) {
    const state = { ...wrappedState.state };
    state.userPreferences.hasSeenSignup = true;
    setWrappedState(wrapLocalUpdate(state));
}

function setHasSeenFrontPage(wrappedState: any, setWrappedState: any) {
    const state = { ...wrappedState.state };
    state.userPreferences.hasSeenFrontPage = true;
    setWrappedState(wrapLocalUpdate(state));
}

function seenProduct(wrappedState: any, setWrappedState: any) {
    const state = { ...wrappedState.state };
    state.userPreferences.seenProducts = (state.userPreferences.seenProducts || 0) + 1;
    setWrappedState(wrapLocalUpdate(state));
}

function setPaymentResult(
    paymentResult: any,
    centra: any,
    wrappedState: any,
    setWrappedState: any
) {
    const state = { ...wrappedState.state };
    setWrappedState(
        wrapLocalUpdate({
            ...state,
            centra: centra,
            centraPaymentResult: paymentResult,
        })
    );
}

// Everything here is deprecated, and should move to more local state
const Provider = (props: any) => {
    const [wrappedState, setWrappedState] = useState(readValueFromLocalStorage());

    useEffect(() => {
        // Make sure we don't have old shopping carts, always start by refreshing the shopping cart
        // and all other data from centra
        if (!window.location.pathname.endsWith('check-payment')) {
            refreshCentraSelection(wrappedState, setWrappedState).catch(err => console.warn(err));
        }
    }, []);

    useEffect(() => {
        let listener = (event: StorageEvent) => {
            if (event.key === localStorageKey) {
                setWrappedState(() => {
                    return wrapRemoteUpdate(parseEvent(event));
                });
            }
        };

        // We want to update the state when the local state is changing. This happens for instance
        // when another tab is changing something
        window.addEventListener('storage', listener);
        return () => {
            window.removeEventListener('storage', listener);
        };
    }, []);

    useEffect(() => {
        maybeWriteToLocalStorage(wrappedState);
    }, [wrappedState]);

    useEffect(() => {
        if (MC_CID.mc_cid) {
            setWrappedState(oldState => {
                const state = { ...oldState.state };
                state.mc = {};
                state.mc.mailchimpCampaignId = MC_CID.mc_cid;
                state.mc.mailchimpLandingSite = window.location.origin + window.location.pathname;
                return wrapLocalUpdate(state);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <NpContextProvider
            value={{
                centraApiToken: wrappedState.state.centra?.token,
                userPreferences: {
                    setHasSeenSignup: () => setHasSeenSignup(wrappedState, setWrappedState),
                    setHasSeenFrontPage: () => setHasSeenFrontPage(wrappedState, setWrappedState),
                    seenProduct: () => seenProduct(wrappedState, setWrappedState),
                    setCountry: (countryCodeEg_GB: any) =>
                        setCentraCountry(countryCodeEg_GB, wrappedState, setWrappedState),
                    setLanguage: (languageCodeEg_en: any) =>
                        setCentraLanguage(languageCodeEg_en, wrappedState, setWrappedState),
                },
                centra: {
                    getNewSelection: () => getNewSelection(wrappedState, setWrappedState),
                    setPaymentResult: (paymentResult: any, centra: any) =>
                        setPaymentResult(paymentResult, centra, wrappedState, setWrappedState),
                    autoCountry: (successFn: any, errorFn: any) => autoCountry(successFn, errorFn),
                    addVoucher: (voucherCode: any, onErrorFn: any) =>
                        addCentraVoucher(voucherCode, onErrorFn, wrappedState, setWrappedState),
                    deleteVoucher: (voucherCode: any, onErrorFn: any) =>
                        deleteCentraVoucher(voucherCode, onErrorFn, wrappedState, setWrappedState),
                    /**
                     * @deprecated Dont use this, instead use one of the hooks in `api/centra`
                     */
                    liveVariants: (variantIds: any, variantFn: any) => {
                        liveVariants(variantIds, variantFn, wrappedState);
                    },
                    addToCart: (centraItemId: any) =>
                        addCentraItem(centraItemId, wrappedState, setWrappedState),
                    decreaseQuantity: (centraLineId: any) =>
                        decreaseQuantityOfCentraItemLine(
                            centraLineId,
                            wrappedState,
                            setWrappedState
                        ),
                    increaseQuantity: (centraLineId: any) =>
                        increaseQuantityOfCentraItemLine(
                            centraLineId,
                            wrappedState,
                            setWrappedState
                        ),
                    initiatePayment: (
                        language: any,
                        customer: any,
                        separateShippingAddress: any,
                        onSuccessFn: any,
                        onErrorFn: any
                    ) =>
                        initiateCentraPayment(
                            language,
                            customer,
                            separateShippingAddress,
                            wrappedState,
                            setWrappedState,
                            onSuccessFn,
                            onErrorFn
                        ),
                    signUpNewsLetter: (
                        email: any,
                        language: any,
                        onSuccessFn: any,
                        onErrorFn: any
                    ) =>
                        signUpNewsLetterSubscription(
                            wrappedState,
                            onSuccessFn,
                            onErrorFn,
                            email,
                            language
                        ),
                    paymentResult: (paymentMethodFields: any, onSuccessFn: any, onErrorFn: any) =>
                        centraPaymentResult(
                            paymentMethodFields,
                            wrappedState,
                            setWrappedState,
                            onSuccessFn,
                            onErrorFn
                        ),
                    refreshSelection: () => refreshCentraSelection(wrappedState, setWrappedState),
                    clearStoredCentraData: () =>
                        clearStoredCentraData(wrappedState, setWrappedState),
                },
                state: wrappedState.state,
            }}
        >
            {props.children}
        </NpContextProvider>
    );
};

const sentryErrorHandler = (error: Error, { componentStack }: { componentStack: string }) => {
    Sentry.withScope(scope => {
        scope.setExtras({ componentStack });
        Sentry.captureException(error);
    });
};

export const InnerProvider = Provider;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 30000,
        },
    },
});

const config = {
    url: 'https://northern-playground-unleash.app.iterate.no/api/frontend', // Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
    clientKey:
        process.env.NODE_ENV === 'production'
            ? '*:production.f2726c4e5ef6113ab0e0e1d2eee11e348ada3bdca8da7f8f0688f95a'
            : '*:development.2dccb9ae9e9f66af1b575d198fec823a61ce22707d0e9787c5e71b6c', // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
    refreshInterval: 15, // How often (in seconds) the client should poll the proxy for updates
    appName: 'gatsby-site', // The name of your application. It's only used for identifying your application
    environment: unleashEnvironment(),
};

function unleashEnvironment() {
    if (typeof window === 'undefined') {
        return 'production';
    }

    let host = window.location.host;

    if (host === 'northernplayground.no' || host === 'www.northernplayground.no') {
        return 'production';
    }

    return 'test';
}

export default ({ element }: any) => (
    // This is added here because this is where the wrapper is defined. This should maybe be moved
    // out at some point?
    // <StrictMode>
    <ErrorBoundary
        FallbackComponent={ErrorPage}
        onReset={() => {
            // reset the state of your app so the error doesn't happen again
            location.reload();
        }}
        onError={sentryErrorHandler}
    >
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <LoginContextProvider>
                <Provider>
                    <FlagProvider config={config}>{element}</FlagProvider>
                </Provider>
            </LoginContextProvider>
        </QueryClientProvider>
    </ErrorBoundary>
    // </StrictMode>
);
