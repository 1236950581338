import axios from 'axios';

import { createUseDataApi } from './useDataApi';

export const npBackendApi = axios.create({
    baseURL: process.env.GATSBY_NORTHERN_PLAYGROUND_BACKEND,
    withCredentials: true,
});

export const useNpDataApi = createUseDataApi(npBackendApi);
