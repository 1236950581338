/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import * as Sentry from '@sentry/browser';
// This set a attribute on body saying what input type the user is using. This is used to disable
// focus states when keyboard input is not used
import 'what-input';

import Provider from './src/provider';
import { RouteUpdateArgs } from 'gatsby';

export const wrapRootElement = Provider;

export const onRouteUpdate = ({ location, prevLocation }: RouteUpdateArgs): void => {
    setTimeout(
        () => {
                prevLocation &&
                window.dataLayer &&
                window.dataLayer.push({
                    event: 'page_view',
                    page: {
                        path: location.pathname, // Without query parameters
                        title: document.title,
                    },
                })
        },
        250
    );
};

// From https://github.com/gatsbyjs/gatsby/issues/2177#issuecomment-400976675
export const onClientEntry = async (): Promise<void> => {
    if (process.env.NODE_ENV === 'production') {
        Sentry.init({
            dsn: 'https://ef9ac444d03044e6abdf5809b596dd75@o402078.ingest.sentry.io/5264058',
        });
    }

    await new Promise((resolve, reject) => {
        // Load polyfill on browsers without fetc, That means basically just IE 11.

        (window as any).__polyfillio__ = () => {
            resolve(undefined);
        };

        if ('fetch' in window) {
            resolve(undefined);
            return;
        }

        const s = document.createElement('script');
        s.src = `https://polyfill.io/v3/polyfill.min.js?version=3.52.1&callback=__polyfillio__&features=es2017`;
        s.async = true;
        s.onerror = reject;
        document.head.appendChild(s);
    });
};
