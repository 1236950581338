import React, { FC, useState } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import Slider from 'react-slick';
import * as sanity from '../sanity-image';
import { IFactBox, ISanityImage, Language } from '../interfaces';
import { ExternalEmbed } from './externalEmbed';
import ExternalLink from './externalLink';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { buttonStyling, filledButton, fontSize, imgStyling, space, zIndex } from './styles';
import constants from '../constants';
import ProductSelection from './productSelection';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { LocaleString, RichTextSerializerQuery } from '../../graphql-types';
import { LoginOrRegisterModal } from './auth/LoginOrRegisterModal';
import { SubmitIdeaModal } from './community/SubmitIdeaModal';
import { createMap } from '../lib/utils';
import { useLanguage } from '../i18n';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { ProductCardData, ProductCardProduct } from './ProductCard';
import TableRenderer from './TableRenderer';

const createSerializers = (
    sizes: string,
    productSelectionSizes: string,
    displayOptions: DisplayOptions,
    InnerBlockContent: FC<{
        blocks: any;
        className?: string;
        displayOptions?: DisplayOptions;
    }>
) => ({
    types: {
        blockColumns2: ({ node }: any) => {
            return (
                <Columns verticalAlign={node.verticalAlign} displayOptions={displayOptions}>
                    <InnerBlockContent blocks={node.column1} />
                    <InnerBlockContent blocks={node.column2} />
                </Columns>
            );
        },
        blockColumns3: ({ node }: any) => {
            return (
                <Columns verticalAlign={node.verticalAlign} displayOptions={displayOptions}>
                    <InnerBlockContent blocks={node.column1} />
                    <InnerBlockContent blocks={node.column2} />
                    <InnerBlockContent blocks={node.column3} />
                </Columns>
            );
        },
        blockColumns4: ({ node }: any) => {
            return (
                <Columns verticalAlign={node.verticalAlign} displayOptions={displayOptions}>
                    <InnerBlockContent blocks={node.column1} />
                    <InnerBlockContent blocks={node.column2} />
                    <InnerBlockContent blocks={node.column3} />
                    <InnerBlockContent blocks={node.column4} />
                </Columns>
            );
        },
        block: (props: any) => {
            if (props.node.style === 'centered') {
                return (
                    <p
                        css={`
                            max-width: ${displayOptions.textMaxWidth ?? displayOptions.maxWidth};
                            text-align: center;
                            margin: 24px auto;
                        `}
                    >
                        {props.children}
                    </p>
                );
            }
            return (
                <div
                    css={`
                        max-width: ${displayOptions.maxWidth};
                        margin: 24px auto;

                        & > * {
                            max-width: ${displayOptions.textMaxWidth};
                        }
                    `}
                >
                    {BlockContent.defaultSerializers.types.block(props)}
                </div>
            );
        },
        factBox: (props: IFactBox) => (
            <div
                css={`
                    max-width: ${displayOptions.mediaMaxWidth ?? displayOptions.maxWidth};
                    margin: 0 auto 64px;
                    background: ${props.node.color};
                    color: ${props.node.textColor ?? 'white'};
                    width: 100%;
                    padding: 32px;
                    border-radius: 4px;

                    & > p:first-child {
                        margin-top: 0;
                    }

                    & > p:last-child {
                        margin-bottom: 0;
                    }

                    /* && to override the other a */
                    && a {
                        color: ${props.node.textColor ?? 'white'};
                    }
                `}
            >
                <InnerBlockContent
                    blocks={props.node.body}
                    displayOptions={{
                        maxWidth: '100%',
                        maxWidthProductSelection: '100%',
                        // Same as text width, but remove the fact box padding
                        textMaxWidth: `calc(${displayOptions.textMaxWidth} - 32px)`,
                    }}
                />
            </div>
        ),
        image: ({ node }: any) => {
            if (!node) {
                return null;
            }

            return (
                <div
                    css={`
                        width: 100%;
                        max-width: ${displayOptions.mediaMaxWidth ?? displayOptions.maxWidth};
                        margin: 64px auto;
                    `}
                >
                    <figure
                        css={`
                            margin: 0;
                            width: 100%;
                        `}
                    >
                        <img
                            css={`
                                ${imgStyling};
                                width: 100%;
                            `}
                            src={sanity.urlFor(node).width(800).url() || undefined}
                            sizes={sizes}
                            srcSet={sanity.srcSet([[1600], [1200], [800], [400], [200]], node)}
                        />
                    </figure>
                </div>
            );
        },
        blockContentImage: ({ node }: any) => {
            if (!node.image) {
                return null;
            }

            return (
                <div
                    css={`
                        max-width: ${displayOptions.mediaMaxWidth ?? displayOptions.maxWidth};
                        width: 100%;
                        margin: 64px auto;
                        display: flex;

                        ${node.align === 'left' && `justify-content: flex-start`};
                        ${node.align === 'center' && `justify-content: center`};
                        ${node.align === 'right' && `justify-content: flex-end`};
                    `}
                >
                    <figure
                        css={`
                            margin: 0;
                            width: 100%;
                            max-width: ${`${node.maxWidthPx}px` ?? '100%'};
                            font-style: italic;
                            text-align: center;
                        `}
                    >
                        <img
                            css={`
                                width: 100%;
                                ${imgStyling};
                            `}
                            src={sanity.urlFor(node.image).width(800).url() || undefined}
                            sizes={sizes}
                            srcSet={sanity.srcSet(
                                [[1600], [1200], [800], [400], [200]],
                                node.image
                            )}
                        />
                        {node.caption && <figcaption>{node.caption}</figcaption>}
                    </figure>
                </div>
            );
        },
        embed: ({ node }: any) => {
            return (
                <div
                    css={`
                        max-width: ${displayOptions.mediaMaxWidth ?? displayOptions.maxWidth};
                        margin: 64px auto;
                    `}
                >
                    <ExternalEmbed url={node.url} autoplay={node.autoplay} loop={node.loop} />
                </div>
            );
        },
        blockHtml: ({ node }: any) => {
            return (
                <BlockHtmlStyle
                    css={`
                        max-width: ${displayOptions.mediaMaxWidth ?? displayOptions.maxWidth};
                        margin: 64px auto;
                        & iframe {
                            max-width: 100%;
                        }
                    `}
                    dangerouslySetInnerHTML={{
                        __html: node.html,
                    }}
                />
            );
        },
        productSelection: ({ node }: any) => {
            return (
                <div
                    css={`
                        max-width: ${displayOptions.maxWidthProductSelection};
                        margin: 0 auto;
                    `}
                >
                    <RichTextProductSelection
                        products={node.products}
                        productCards={node.productCards}
                        imgSizes={productSelectionSizes}
                    />
                </div>
            );
        },
        carousel: ({ node }: any) => {
            return (
                <div
                    css={`
                        max-width: ${displayOptions.mediaMaxWidth ?? displayOptions.maxWidth};
                        margin: 64px auto;
                    `}
                >
                    <StyledSlider
                        dots={true}
                        arrows={true}
                        infinite={true}
                        nextArrow={<NextArrow />}
                        prevArrow={<PrevArrow />}
                    >
                        {node.images.map((img: ISanityImage, index: number) => (
                            <img
                                css={`
                                    ${imgStyling};
                                `}
                                key={index}
                                src={sanity.urlFor(img).width(800).url() || undefined}
                                sizes={sizes}
                                srcSet={sanity.srcSet([[1628], [1221], [814], [410], [205]], img)}
                            />
                        ))}
                    </StyledSlider>
                </div>
            );
        },
        button: ({ node }: any) => {
            if (node.buttonType === 'link' || !node.buttonType) {
                return (
                    <div
                        css={`
                            max-width: ${displayOptions.maxWidth};
                            margin: 0 auto 64px;
                        `}
                    >
                        <ButtonWrapper align={node.align}>
                            <a
                                css={`
                                    ${fontSize.regular};
                                    ${filledButton};
                                    padding: 0 ${space(2)};
                                    line-height: 48px;
                                    display: inline-block;
                                    font-weight: 500;
                                `}
                                href={node.url}
                            >
                                {node.text}
                            </a>
                        </ButtonWrapper>
                    </div>
                );
            } else if (node.buttonType === 'login' || node.buttonType === 'register') {
                return (
                    <LoginOrRegisterButton
                        displayOptions={displayOptions}
                        type={node.buttonType}
                        align={node.align}
                    >
                        {node.text}
                    </LoginOrRegisterButton>
                );
            } else if (node.buttonType === 'submitIdea') {
                return (
                    <SubmitIdeaButton displayOptions={displayOptions} align={node.align}>
                        {node.text}
                    </SubmitIdeaButton>
                );
            } else {
                return <>Unknown type {node.buttonType}</>;
            }
        },
        break: ({ node }: any) => {
            const { style } = node;
            if (style === 'horizontal-ruler') {
                return (
                    <hr
                        css={`
                            border: none;
                            border-bottom: 1px solid ${constants.colors.pallet.disabled};
                            max-width: ${space(10)};
                            margin: -8px 0;
                            padding: 0;
                        `}
                    />
                );
            }
            return null;
        },
        table: ({ node }: any) => {
            return <TableRenderer tableData={node}></TableRenderer>;
        },
    },
    marks: {
        link: ({ mark, children }: any) => {
            return (
                <ExternalLink
                    css={`
                        color: inherit;
                    `}
                    openInNewTab={false}
                    address={mark.href}
                    text={children}
                    style={{ textDecoration: 'underline' }}
                />
            );
        },
        textColor: ({ mark, children }: any) => {
            return (
                <span
                    css={`
                        color: ${mark.color};
                    `}
                >
                    {children}
                </span>
            );
        },
    },
    list: (props: any) => {
        return (
            <div
                css={`
                    max-width: ${displayOptions.maxWidth};
                    margin: 24px auto;

                    & > * {
                        max-width: ${displayOptions.textMaxWidth};
                    }
                `}
            >
                {BlockContent.defaultSerializers.list(props)}
            </div>
        );
    },
});

export const Columns = styled.div<{ displayOptions: DisplayOptions; verticalAlign: string }>`
    margin: 64px auto;
    max-width: ${props => props.displayOptions.maxWidth};

    @media (min-width: 500px) {
        display: flex;
        flex-direction: row;
        ${props => props.verticalAlign === 'top' && `align-items: flex-start`};
        ${props => props.verticalAlign === 'center' && `align-items: center`};
        ${props => props.verticalAlign === 'bottom' && `align-items: flex-end`};
        & > * {
            flex-basis: 1px;
            flex-grow: 1;
            padding: 0 20px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }

            /* The columns-div has top and bottom margin, so remove it on it's first
           and last children */
            *:first-child {
                margin-top: 0;
            }
            *:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

const StyledSlider = styled(Slider)`
    .slides .slick-prev,
    .slides .slick-next {
        position: absolute;
        top: 50%;
        z-index: ${zIndex.pageElement};
    }

    .slick-slide img {
        margin: 0;
    }

    .slick-dots {
        bottom: 25px;

        li button:before {
            ${fontSize.regular};
        }
    }
`;

export type DisplayOptions = {
    // max width for everything except for product selection
    maxWidth: string;
    // max width for product selection
    maxWidthProductSelection: string;
    // if set (to a lower value than maxWidth), media will be smaller
    // then maxWidth, left justified
    mediaMaxWidth?: string;
    // if set (to a lower value than maxWidth), text will be smaller
    // then maxWidth, left justified
    textMaxWidth?: string;
};

const RichTextSerializer: React.FC<{
    content: any;
    sizes: string;
    displayOptions: DisplayOptions;
    className?: string;
    productSelectionSizes?: string;
}> = ({ content, sizes, productSelectionSizes, className, displayOptions }) => {
    let productSelectionSizesOrDefault = productSelectionSizes ?? sizes;
    let InnerBlockContent: FC<{
        blocks: any;
        className?: string;
        displayOptions?: DisplayOptions;
    }> = ({ blocks, className, displayOptions }) => {
        let innerSerializers = createSerializers(
            sizes,
            productSelectionSizesOrDefault,

            displayOptions ?? {
                // Inside other boxes the sizes should be adjusted based on the outer box
                maxWidth: '100%',
                maxWidthProductSelection: '100%',
            },
            InnerBlockContent
        );

        if (!blocks) {
            return <div />;
        }

        return (
            <BlockContent
                className={`inner-block-content ${className}`}
                blocks={blocks}
                serializers={innerSerializers}
                renderContainerOnSingleChild={true}
            />
        );
    };

    let serializers = createSerializers(
        sizes,
        productSelectionSizesOrDefault,
        displayOptions,
        InnerBlockContent
    );

    if (!content) {
        return <div />;
    }

    return (
        <RootBlockContent
            className={className}
            blocks={content}
            serializers={serializers}
            renderContainerOnSingleChild={true}
        />
    );
};

const RichTextProductSelection: React.FC<{
    products?: { _id?: string; _ref?: string }[];
    productCards?: {
        product: { _id?: string; _ref?: string };
        title?: LocaleString;
        image?: SanityImageSource;
    }[];
    imgSizes: string;
}> = ({ products, productCards, imgSizes }) => {
    // We fetch all products. We might want to change this at a later data. For instance we can have
    // a plugin which adds product references to all "portable text", but that is a premature
    // optimization right now
    const allProducts = useStaticQuery<RichTextSerializerQuery>(graphql`
        query RichTextSerializer {
            allProduct {
                nodes {
                    id
                    sanityId
                    productState
                    crowdfundingDate
                    crowdfundingGoal
                    slug {
                        en
                        nb
                    }
                    clothingType {
                        id
                    }
                    productState
                    centraId
                    title {
                        en
                        nb
                    }
                    collection {
                        slug {
                            nb {
                                current
                            }
                            en {
                                current
                            }
                        }
                    }
                    colors {
                        hex
                        title
                    }
                    sanityProduct {
                        productCardTitle {
                            en
                            nb
                        }
                        productCardImageTag {
                            nb
                            en
                        }
                        title {
                            nb
                            en
                        }
                        images {
                            __typename

                            ... on SanityProductImage {
                                image: _rawImage
                            }
                        }
                    }
                }
            }
        }
    `);

    const sanityIdToProduct = createMap(
        allProducts.allProduct.nodes.map(product => [product.sanityId, product])
    );

    const productsWithMoreData: ProductCardProduct[] | undefined = products
        ?.map(
            // For some products we might have a _ref instead of an _id, when the product is not fully fetched
            product =>
                (product._id || product._ref) &&
                sanityIdToProduct[product._id ?? product._ref ?? ''] &&
                sanityIdToProduct[product._id ?? product._ref ?? ''].sanityProduct.images &&
                sanityIdToProduct[product._id ?? product._ref ?? '']
        )
        .filter(notEmpty);

    const productCardsWithMoreData: ProductCardData[] | undefined = productCards
        ?.map(
            // For some products we might have a _ref instead of an _id, when the product is not fully fetched
            productCard => {
                let product =
                    (productCard.product._id || productCard.product._ref) &&
                    sanityIdToProduct[productCard.product._id ?? productCard.product._ref ?? ''] &&
                    sanityIdToProduct[productCard.product._id ?? productCard.product._ref ?? '']
                        .sanityProduct.images &&
                    sanityIdToProduct[productCard.product._id ?? productCard.product._ref ?? ''];
                if (!product) {
                    return null;
                }
                return {
                    ...productCard,
                    product,
                };
            }
        )
        .filter(notEmpty);

    return (
        <ProductSelection
            imgSizes={imgSizes}
            products={productsWithMoreData}
            productCards={productCardsWithMoreData}
        />
    );
};

function notEmpty<TValue>(value: TValue | null | undefined | ''): value is TValue {
    return value !== null && value !== undefined;
}

const RootBlockContent = styled(BlockContent)<{ displayOptions: DisplayOptions }>`
    box-sizing: border-box;

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
    figure {
        margin: 0px;
    }

    img {
        width: 100%;
    }
`;

const Arrow = styled.div`
    width: 30px;
    position: absolute;
    top: 50%;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    z-index: ${zIndex.pageElement};
    color: #fff;
`;

const NextArrow: React.FC<{ style?: React.CSSProperties; onClick?: () => void }> = ({
    style,
    onClick,
}) => {
    return (
        <Arrow onClick={onClick} style={{ ...style, right: '25px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path
                    fill="currentColor"
                    d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                ></path>
            </svg>
        </Arrow>
    );
};

const PrevArrow: React.FC<{ style?: React.CSSProperties; onClick?: () => void }> = ({
    style,
    onClick,
}) => {
    return (
        <Arrow onClick={onClick} style={{ ...style, left: '25px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path
                    fill="currentColor"
                    d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                ></path>
            </svg>
        </Arrow>
    );
};

const BlockHtmlStyle = styled.div`
    /* Add some styling for mailchimp forms added via html embed */
    #mc_embed_signup {
        font: inherit;
        label {
            text-transform: none;
            ${fontSize.regular};
        }

        input {
            border-radius: 4px;
            ${fontSize.regular};
        }

        h2 {
            ${fontSize.large};
        }

        form {
            padding: 64px 0;
        }

        .button {
            ${buttonStyling};
            width: auto;
            transition: none;
            padding: 0 20px;
            transition: transform 200ms ease-in-out;

            &:hover {
                background-color: ${constants.colors.pallet.black};
                transform: rotate(-3deg);
            }
        }
    }
`;

const ButtonWrapper = styled.div<{ align: string }>`
    display: flex;
    flex-direction: row;
    ${props => {
        if (props.align === 'left') {
            return `justify-content: flex-start;`;
        }
        if (props.align === 'center') {
            return `justify-content: center;`;
        }
        if (props.align === 'right') {
            return `justify-content: flex-end;`;
        }
    }}
`;

const LoginOrRegisterButton: FC<{
    type: 'login' | 'register';
    align: string;
    displayOptions: DisplayOptions;
    children: React.ReactNode;
}> = ({ type, displayOptions, children, align }) => {
    let [showLoginModal, setShowLoginModal] = useState(false);
    let language = useLanguage();

    return (
        <div
            css={`
                max-width: ${displayOptions.maxWidth};
                margin: 0 auto 64px;
            `}
        >
            {showLoginModal && (
                <LoginOrRegisterModal
                    initialState={{ type }}
                    doClose={() => {
                        setShowLoginModal(false);

                        // When logging in on the community page, we want to redirect to "thanks for joinint the community"
                        if (window.location.pathname.endsWith('/content/community')) {
                            void navigate(`/${language}/community`);
                        }
                    }}
                />
            )}
            <ButtonWrapper align={align}>
                <button
                    onClick={() => {
                        setShowLoginModal(true);
                    }}
                    css={`
                        ${fontSize.regular};
                        ${filledButton};
                        padding: 0 ${space(2)};
                        line-height: 48px;
                        display: inline-block;
                        font-weight: 500;
                    `}
                >
                    {children}
                </button>
            </ButtonWrapper>
        </div>
    );
};

const SubmitIdeaButton: FC<{
    align: string;
    displayOptions: DisplayOptions;
    children: React.ReactNode;
}> = ({ displayOptions, children, align }) => {
    let [showModal, setShowModal] = useState(false);

    return (
        <div
            css={`
                max-width: ${displayOptions.maxWidth};
                margin: 0 auto 64px;
            `}
        >
            {showModal && (
                <SubmitIdeaModal
                    close={() => {
                        setShowModal(false);
                    }}
                />
            )}
            <ButtonWrapper align={align}>
                <button
                    onClick={() => {
                        setShowModal(true);
                    }}
                    css={`
                        ${fontSize.regular};
                        ${filledButton};
                        padding: 0 ${space(2)};
                        line-height: 48px;
                        display: inline-block;
                        font-weight: 500;
                    `}
                >
                    {children}
                </button>
            </ButtonWrapper>
        </div>
    );
};

export default RichTextSerializer;
